<template>
    <CRow>
        <CCol sm="12">
            <TopHeadline headerTitle="Xuất sản phẩm bán lẻ" backTitle="Quay lại" :onGoBack="onGoBack">
            </TopHeadline>
            <CRow>
                <div class="col-sm-6 wrap-inventory">
                    <CCard class="card-secondary card-outline">
                        <CCardHeader>
                            <div class="clearfix">
                                <div class="float-left">
                                    <h5 class="mb-0">Thông tin tồn kho</h5>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <form v-if="model != null">
                                <div class="form-group row">
                                    <span class="col-sm-4"><img :src="model.product.defaultPictureModel.imageUrl" class="img-thumbnail rounded float-right" alt="Hình Đại Lý" /></span>
                                    <span class="col-sm-8">
                                        <span class="row store-title">{{model.product.name}}</span>
                                        <span class="row store-title-i">{{model.agent.name}}</span>
                                    </span>
                                </div>
                                <div class="form-group row">
                                    <span class="col-sm-4 text-right">Số lượng trong kho sỉ:</span>
                                    <span class="col-sm-2"><input class="form-control store-number" :value="model.wholesaleQuantity.availableQuantity" readonly></span>
                                    <span class="col-sm-6 store-unit" v-if="model.wholesaleQuantity.unit != null">{{model.wholesaleQuantity.unit.name}}<span class="ml-1"><i>({{model.product.packagingSpecification}})</i></span></span>
                                </div>


                                <div class="form-group row" v-for="(item, index) in model.retailQuantities" :key="item.unitId">
                                    <span class="col-sm-4 text-right" v-if="index==0">Số lượng trong kho lẻ:</span>
                                    <span class="col-sm-4 text-right" v-if="index > 0">&nbsp;</span>
                                    <span class="col-sm-2"><input class="form-control store-number" :value="item.availableQuantity" readonly></span>
                                    <span class="col-sm-6 store-unit">{{item.unit.name}}</span>
                                </div>
                            </form>
                        </CCardBody>
                    </CCard>
                </div>

                <div class="col-sm-6 wrap-inventory">
                    <CCard class="card-secondary card-outline">
                        <CCardHeader>
                            <div class="clearfix">
                                <div class="float-left">
                                    <h5 class="mb-0">Xuất bán lẻ</h5>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <form v-if="model != null && values.toQuantities != null">
                                <div class="form-group row">
                                    <span class="col-sm-4 text-right">Lấy từ kho sỉ:</span>
                                    <span class="col-sm-2"><input class="form-control store-number" v-model="values.fromQty"></span>
                                    <span class="col-sm-6 store-unit">thùng</span>
                                </div>
                                <div class="form-group row" v-if="!!errors.fromQty">
                                    <div class="col-sm-4"></div>
                                    <span class="col-sm-8 errors">
                                        {{errors.fromQty}}
                                    </span>
                                </div>
                                <div v-for="(item, index) in values.toQuantities" :key="item.unitId">
                                    <div class="form-group row">
                                        <span class="col-sm-4 text-right" v-if="index==0">Chuyển sang kho lẻ:</span>
                                        <span class="col-sm-4 text-right" v-if="index > 0">&nbsp;</span>
                                        <span class="col-sm-2"><input class="form-control store-number" v-model="item.quantity"></span>
                                        <span class="col-sm-6 store-unit">{{item.unit.name}}</span>
                                    </div>

                                    <div class="form-group row" v-if="!!errors[`toQuantities[${index}].quantity`]">
                                        <div class="col-sm-4"></div>
                                        <span class="col-sm-8 errors">
                                            {{errors[`toQuantities[${index}].quantity`]}}
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <span class="col-sm-4 text-right">&nbsp;</span>
                                    <span class="col-sm-8">
                                        <button type="button" class="btn btn-success" @click="onDistribute">Đồng Ý</button>
                                        <button type="reset" class="btn btn-secondary ml-1">Hủy</button>
                                    </span>
                                </div>
                            </form>
                        </CCardBody>
                    </CCard>
                </div>

            </CRow>

        </CCol>
    </CRow>

</template>

<script>
    import TopHeadline from '@/components/TopHeadline.vue'


    import { globalConfigs } from '@/global/index'
    console.log("Global", globalConfigs);

    import _ from 'lodash'
    import * as yup from 'yup'

    const distributionSchema = yup.object().shape({
        toQuantities: yup.array().of(yup.object().shape({
            quantity: yup.number().min(0,"Số lượng chưa hợp lý")
        })),
        fromQty: yup.number().min(1,"Số lượng xuất phải lớn hơn 0")
    });
    export default {
        name: 'RetailInventoryAdd',
        data() {
            return {
                values: {
                    fromQty: 0,
                    toQuantities: []
                },
                errors: {
                    fromQty: '',
                    toQuantities: [{
                        quantity: ''
                    }]
                },
                model: null
            }
        },
        components: {
            TopHeadline,

        },
        computed: {
        },
        methods: {
            validator(val) {
                return val ? val.length > 0 : false
            },
            onlyNumbers(event) {
                let keyCode = event.keyCode ? event.keyCode : event.which;
                if (keyCode < 48 || keyCode > 57) {
                    // 46 is dot
                    event.preventDefault();
                }
            },
            onGoBack() {
                this.$router.push('/warehouses/inventorylist');
            },
            validate(field) {
                distributionSchema.validateAt(field, this.values)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },
            async loadData(productId, agentId) {
                this.model = await this.$store.$productService.editproductinventory(productId, agentId);
                console.log("RETURN DATA", this.model);
                if (this.model != null) {
                    this.values.toQuantities = _.map(this.model.retailQuantities, (item) => {
                        return {
                            quantity: 0,
                            unitId: item.unitId,
                            unit: item.unit
                        }
                    })
                    this.values.fromQty = 0

                    console.log("BUILD FORM MODEL", this.values.toQuantities)
                }
            },

            async onDistribute() {
                try {
                    await distributionSchema.validate(this.values, { abortEarly: false });
                    this.errors = {};
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.errors[error.path] = error.message;
                        console.log("Validation error", { path: error.path, message: error.message })
                    })
                    console.log("Validation ALL ERROR", this.errors)
                    return;
                }

                var existingItem = _.find(this.values.toQuantities, unitQty => { return unitQty.quantity > 0 })
                if (existingItem == null) {
                    this.$store.dispatch('alert/error', 'Hãy nhập số lượng bên kho lẻ', { root: true });
                    return;
                }

                if (this.model != null) {
                    try {

                        if (this.values.fromQty > this.model.wholesaleQuantity.availableQuantity)
                        {
                            this.$store.dispatch('alert/error', 'Không đủ số lượng', { root: true });
                            return;
                        }

                        var retVal = await this.$store.$productService.distributeToRetail(this.model.product.id, this.model.agent.id, this.values.fromQty, this.values.toQuantities)
                        if (retVal.success) {
                            this.$store.dispatch('toast/displayNotification', { text: 'Phân phối sang kho lẻ thành công.' }, { root: true });
                            await this.loadData(this.model.product.id, this.model.agent.id)
                        }
                            
                    }
                    catch (err) {
                        this.$store.dispatch('alert/error', err.message, { root: true });
                    }
                }
            }
        },
        async mounted() {
            this.$store.dispatch("product/getAllUnits");
            var p = this.$route.query.p;
            var ag = this.$route.query.ag;
            var productId = parseInt(p);
            var agentId = parseInt(ag);
            console.log('ag & p', {ag,p})
            if (!isNaN(productId) && !isNaN(agentId)) {
                await this.loadData(productId,agentId)
            }
        },
    }
</script>

<style scoped>
    .rotating {
        transition: transform 1s ease-in-out;
    }

        .rotating:hover {
            transform: rotateZ(360deg);
        }

    .wrap-inventory {
        padding: 15px;
    }
    .store-title{
        font-weight: bold;
        font-size: 1.2rem;
    }
    .store-title-i {
        font-style: italic;
        font-size: 1rem;
    }
    .store-number {
        border: 0 none !important;
        border-bottom: 1px solid #999999 !important;
        text-align: right;
        font-weight: bold;
        border-radius: 0;
        color: #006622;
        height: 1.6em;
        padding: 0 5px;
    }
        .store-number:read-only{
            background: #ffffff;
            color: #333333;
            font-weight: bold;
        }
    .store-number:focus{
        outline: none;
        border: none;
        box-shadow: none;
    }
    span.errors {
        color:red
    }
</style>